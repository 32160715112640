<template>
  <header v-if="$route.name == 'Artifact' || $route.name == 'Shelf' ||  $route.name == 'About'">
    <router-link to="/"><img class="logo" alt="#InDreams" src="../assets/logo.png" ></router-link>
    <router-link to="/randomizer"><img class="logo" alt="Randomizer" src="../assets/cb_tiny_1.png" ></router-link>
    <Countdown/>
  </header>
</template>
<style lang="scss">
  header {
    border-bottom: 1px solid #ccc;
    background: #FFF;
    box-shadow: 0px 3px 3px rgba(0,0,0,.1);
    padding: 0 1rem;
    .countdown {
      float: right;
      padding-top: .2rem;
      margin-right: 2rem;
    }
    .logo {
      height: 90px;
    }
    p {
      margin-left: 1rem;
    }
  }
  @media screen and (max-width: 940px) {
      header {
      /*single column layout*/
        display: block;
        .countdown {
          padding-bottom: 1rem;
          margin: 0 auto;
          display: block;
          float: none;
          text-align: center;
        }
        img {
          margin: 0 auto;
          display: block;
        }
      }
      .countdown_text {
        display: none;
      }
  }
</style>
<script>
import { defineComponent } from 'vue'
import Countdown from '@/components/Countdown.vue'

export default defineComponent({
  name: 'AppHeader',
  components: {
    Countdown
  }
})
</script>
