
<template>
    <div class="share_button" >
        <a  v-if="this.url"
          :href="this.tweetIntent"
        >
          Share this on twitter.
        </a>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TweetThis',
  props: ['url', 'text'],
  computed: {
    tweetIntent () {
      let result = 'https://twitter.com/intent/tweet?text='
      result += encodeURIComponent(this.text)
      result += '&url=' + encodeURIComponent(this.url)
      result += '&hashtags=InDreamsJam'
      return result
    }
  }
})
</script>
