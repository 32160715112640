<template>
  <div class="artifact_wrap" ref="wrap">
    <slot></slot>
    <div v-if="artifact.contents && artifact.contents.length > 0 && expand === true" class="artifact_subset">
        <LazyImage v-for="(content, key) in artifact.contents"
        :class="subclass"
        :key="'subset'+artifact.id+'_'+key"
        :source="imgSource(content.id)"
        :link="linkTo(content.id)"
        :blurhash="content.metadata.blurhash"
        />
    </div>
    <LazyImage v-else-if="artifact.contents && artifact.contents.length > 0"
      :class="subclass"
      :source="imgSource(artifact.contents[0].id)"
      :link="linkTo(artifact.contents[0].id)"
      :blurhash="artifact.contents[0].metadata.blurhash"
      :stacksize='hideStack == true ? 0 : artifact.contents.length'
    />
    <LazyImage v-else
      :class="subclass"
      :height="height"
      :width="width"
      :source="imgSource(artifact.id)"
      :link="linkTo(artifact.id)"
      :blurhash="artifact.metadata.blurhash"
    />
  </div>
</template>

<style>
    .artifact_subset {
        display: inline;
    }
    .artifact_subset .image__wrap {
        transition: opacity .2s ease-in;
    }
    .artifact_subset:hover .lazy_image .image__wrap {
        transition: opacity .5s ease-out;
        opacity: 0.5;
    }
    .artifact_subset:hover .lazy_image:hover .image__wrap {
        opacity: 1.0 !important;
        transition: opacity .2s;
    }
</style>
<script>
import { defineComponent } from 'vue'
import LazyImage from '@/components/LazyImage.vue' // @ is an alias to /src
export default defineComponent({
  name: 'ArtifactOnShelfLazy',
  props: {
    artifact: { type: Object },
    expand: { type: Boolean, default: true },
    useThumbs: { type: Boolean, default: true },
    hideStack: { type: Boolean, default: false },
    subclass: { type: String, default: 'item' },
    link: { type: String, default: undefined },
    height: { type: Number, default: 0 },
    width: { type: Number, default: 0 }
  },
  components: {
    LazyImage
  },
  methods: {
    linkTo (artifactID) {
      if (this.link) {
        return this.link
      }
      if (this.artifact.contents && this.artifact.contents.length > 0) {
        if (this.expand === true || this.hideStack === true) {
          return '/artifact/' + artifactID + '/info'
        }
        return '/shelf/' + this.artifact.shelf + '?art=' + artifactID
      }
      return '/artifact/' + artifactID + '/info'
    },
    imgSource (artifactID) {
      if (this.useThumbs) {
        return '/srv/artifact/' + artifactID + '/g/thumb_346/'
      }
      return '/srv/artifact/' + artifactID + '/'
    }
  }
})
</script>
