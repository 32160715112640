<template>
  <div class="artifact">
  <div class="artifact_display">
    <div class="artifact_meta">
      <div class="artifact_label">
        <h2>{{artifactName}}</h2>
        <h3>(Artifact #{{artifactName}})</h3>
      </div>
      <LinkButton :href="'/srv/artifact/'+id+'/'" :isDownload="'Artifact '+cleanedFilename" :color="accentColor"/>
      <p>This AI-generated artwork is part of the Games We Play In Dreams game jam,  which invites you to write a 1-page roleplaying game based on prompt images. For best inspiration, <router-link to="/randomizer">open the box</router-link> of dreams.</p>

      <p><a href="">Bulk downloads</a> of all images are available at itch.io.</p>
      <p>Optionally, if you want to help make the site better or fund more art, you can <a href="https://ko-fi.com/jeckdev">send me a tip</a>.</p>
      <div v-if="metadata.game">
        <h3>Games:</h3>
        <p>This artifact has inspired the creation of the following games.</p>
        <ul>
          <div v-for="(game,index) in metadata.game" :key="'game'+index">
            <a :href="game.link">{{game.title}}</a><br/><span>by {{game.author}}</span>
          </div>
        </ul>
      </div>
      <h3>License:</h3>
      <p>This image is provided under a <a rel="license" href="http://creativecommons.org/publicdomain/zero/1.0/">CC0 license</a>, meaning it can be used by anyone, in any project— printed or digital, commercial or free.</p>
      <p>To the extent possible under the law, I have waived all copyright and related or neighboring rights to this image.</p>
      <h3>Colors:</h3>
      <PaletteDisplay class="artifact_colors" v-if="metadata.palette" :colors="metadata.palette"/>
    </div>
    <div class="artifact_artifact">
      <LazyImage
        v-if="artifactType === 'image'"
        link="#"
        class="item"
        :source="'/srv/artifact/'+id+'/'"
        :blurhash="metadata.blurhash"
        height="100%"  width="100%"
      />
      <TweetThis class="share_artifact"
        text="I found this in a dream!"
        :url="'https://gamesweplayindreams.com/artifact/'+ id +'/'"
      />
    </div>
  </div>
  </div>
</template>
<style scoped>
    @font-face {
      font-family: "Dreamlike";
      src: url('./../assets/Dreamlike-Regular.otf');
    }
    .artifact_colors {
      margin: 0 auto;
      max-width: 1280px;
    }
    .artifact_label h2 {
      margin: 1em auto;
      padding: 0;
      display: block;
      font-family: Dreamlike;
      text-align: right !important;
      font-size: 2.5em;
      line-height: 1;
      margin-bottom: 0;
    }
    .artifact_label h3 {
      margin-top: 0;
      padding-bottom: .25em;
      text-align: right !important;
      border-bottom: solid 6px  !important;
      font-style: italic;
    }
    .artifact_display {
      display: flex;
      flex-flow: row;
      margin: 0 auto;
      z-index: 2;
      padding: 0 1em;
      background-color: v-bind('bgColor');
      color: v-bind('fgColor');
    }
    .share_artifact {
      text-align: right;
    }
    .artifact h3 {
      text-align: left;
      border-bottom: solid 2px;
    }
    .artifact_display ul {
      text-align: left;
      list-style: none;
    }
    .artifact_display .lazy_image .image__img,
    .artifact_display .lazy_image .image__stack,
    .artifact_display .lazy_image .image__blur {
      border-radius: 2% !important;
    }
    .metadata li {
      margin-bottom: 1em;
    }
    .metadata .label {
      font-weight: 600;
      display: block;
    }
    .artifact_artifact {
      margin-top: 1rem;
      margin-right: 1rem;
      z-index: 2;
      order: 2;
      flex: 5 1 768px;
      max-width: 900px;
    }
    .artifact_meta {
      flex: 1 9 256px;
      max-width: 440px;
      margin: 0 2rem 0 1rem;
      order: 1;
    }
    .artifact a {
      color: v-bind('accentColor');
    }
    .artifact a:hover {
      color: v-bind('fgColor');
    }
    .artifact_display .item {
        break-inside: avoid;
        margin: 1rem auto;
        margin-bottom: 1rem;
    }
    .artifact_display .item canvas, .artifact_display .item img {
        box-shadow: 0px 4px 6px #9D9D9D;
    }
    .artifact_display .image__wrap a {
        padding: 0; margin: 0;
        display: inline;
    }
    @media screen and (max-width: 940px) {
      .artifact_display {
      /*single column layout*/
        display: flex;
        flex-flow: column;
      }
      .artifact_meta, .artifact_display .item {
        max-width: 100%;
      }
      .artifact_meta {
        order: 2;
        flex: 1 1 auto;
      }
      .artifact_artifact {
        order: 1;
        flex: 1 1 auto;
      }
    }
</style>
<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import LazyImage from '@/components/LazyImage.vue' // @ is an alias to /src
import LinkButton from '@/components/LinkButton.vue'
import PaletteDisplay from '@/components/PaletteDisplay.vue'
import TweetThis from '@/components/TweetThis.vue' // @ is an alias to /src

export default defineComponent({
  name: 'ArtifactDisplay',
  props: ['id'],
  data () {
    return {
      shelves: [],
      generated: [],
      metadata: {},
      imported: {},
      mime_type: '',
      file_hash: ''
    }
  },
  computed: {
    artifactName () {
      if (this.metadata.name) {
        return this.id.toUpperCase() // this.metadata.name
      } else if (this.imported && this.imported.filename) {
        return this.id.toUpperCase() // return this.imported.filename
      }
      return this.id.toUpperCase()
    },
    cleanedFilename () {
      return this.id.replace('.', '-')
    },
    artifactType () {
      if (this.mime_type === undefined) {
        return 'unknown'
      } else if (this.mime_type.substring(0, 5) === 'image') {
        return 'image'
      } else if (this.mime_type.substring(0, 5) === 'audio') {
        return 'audio'
      }
      return 'unknown'
    },
    bgColor () {
      if (this.metadata.color_light) {
        return this.hexCode(this.metadata.color_light)
      }
      return ''
    },
    accentColor () {
      if (this.metadata.color_accent) {
        return this.hexCode(this.metadata.color_accent)
      }
      return '#2A2092'
    },
    fgColor () {
      if (this.metadata.color_dark) {
        return this.hexCode(this.metadata.color_dark)
      }
      return ''
    }
  },
  methods: {
    hexCode (rgb) {
      if (rgb && rgb.length >= 3) {
        var r = Number(rgb[0]).toString(16)
        var g = Number(rgb[1]).toString(16)
        var b = Number(rgb[2]).toString(16)
        if (r.length === 1) r = '0' + r
        if (g.length === 1) g = '0' + g
        if (b.length === 1) b = '0' + b
        return '#' + r + g + b
      }
      return ''
    }
  },
  async created () {
    try {
      const response = await axios.get('/api/v1/artifact/' + this.id + '/info')
      console.log('Hit response!')
      this.generated = response.data.artifact.generated
      this.shelves = response.data.artifact.shelves
      this.mime_type = response.data.artifact.mime_type
      this.metadata = response.data.artifact.metadata
      this.imported = response.data.artifact.imported
    } catch (e) {
      console.log('Failed to connect to internal API!')
    }
  },
  components: {
    LazyImage,
    PaletteDisplay,
    TweetThis,
    LinkButton
  }
})
</script>
