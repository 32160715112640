<template>
  <div class="image_board"
    ref="shelfcontainer"
    v-resize="onResize"
  >
    <RecycleScroller page-mode
        class="scroller"
        :items="this.chunkedArtifacts()"
        :item-size="this.rowHeight()"
        key-field="id"
        v-slot="{ item }"
    >
    <div class="shelf_row">
    <ArtifactOnShelfLazy v-for="(artifact, index) in item.artifacts"
      :key="artifact.id + '_p' + index" :artifact="artifact" :expand="false"
      :height="this.rowHeight()-24"
      :width="this.rowHeight()-24"
    />
    </div>
    </RecycleScroller>
  </div>
</template>

<style>
.scroller .shelf_row {
  height: v-bind('rowHeight');
  columns: v-bind('chunks');
  margin: 1em;
}
.scroller {
  margin: 1em;
}

.scroller {
  overflow-y: auto;
  margin: 0 auto;
}
</style>

<script>
import { defineComponent } from 'vue'
import { chunk, map } from 'lodash'

import ArtifactOnShelfLazy from '@/components/ArtifactOnShelfLazy.vue' // @ is an alias to /src

function chunkToItem (n) {
  return {
    id: n[0].id,
    artifacts: n
  }
}

export default defineComponent({
  name: 'ShelfTest',
  props: ['artifacts', 'first'],
  components: {
    ArtifactOnShelfLazy
  },
  data () {
    return {
      width: 0,
      chunks: 5,
      cellHeight: 346
    }
  },
  mounted () {
    this.width = this.$el.offsetWidth
    this.chunks = this.chunkSize()
    this.cellHeight = this.rowHeight()
  },
  computed: {
    sortedArtifacts () {
      var result = []
      if (this.first) {
        console.log('Fetching shelf for ' + this.first)
      } else {
        return this.artifacts
      }
      if (this.artifacts) {
        for (const art of this.artifacts) {
          if (this.first && art.id === this.first) {
            result.unshift(art)
          } else {
            result.push(art)
          }
        }
      }
      return result
    }
  },
  methods: {
    onResize ({ width, height }) {
      this.width = width
      this.chunks = this.chunkSize()
      this.cellHeight = this.rowHeight()
    },
    chunkedArtifacts () {
      return map(chunk(this.sortedArtifacts, this.chunkSize()), chunkToItem)
    },
    chunkSize () {
      if (this.width && this.width > 346) {
        return Math.ceil(this.width / 346) // Target 346x columns.
      }
      return 1
    },
    rowHeight () {
      // Width / 5 == row height
      return Math.min(Math.ceil(this.width / this.chunkSize()), 346)
    }
  }
})
</script>
