<template>
    <div class="countdown" v-if="this.ended">
    </div>
    <div class="countdown" v-else>
        <span class="countdown_text">{{this.text}}</span>
        <div class="countdown_timer">
            <div v-if="this.days > 0"><span class="digit">{{this.days}}</span><span class="unit">days</span></div>
            <div v-if="this.days > 0 || this.hours > 0"><span class="digit">{{this.hours}}</span><span class="unit">hours</span></div>
            <div v-if="this.days > 0 || this.hours > 0 || this.minutes > 0"><span class="digit">{{this.minutes}}</span><span class="unit">minutes</span></div>
        </div>
    </div>
</template>
<style>
    .countdown {
        margin: 0 auto;
        display: inline-block;
        font-size: 1.25rem;
        vertical-align: middle;
    }
    .countdown .countdown_text {
      font-weight: 400;
    }
    .countdown span {
      display: block;
    }
    .countdown_timer {
        text-align: center;
        padding: 0;
    }
    .countdown_timer div {
        display: inline-block;
        color: #000;
        font-weight: 400;
        margin: 0 .2rem;
        padding: .2rem;
        max-height: 50px;
        border-radius: 4px;
        box-shadow: 0px 4px 3px rgba(0,0,0,.1);
    }
    .countdown_timer div span {
        display: block;
    }
    .countdown_timer .unit {
        font-size: .8rem;
        text-align: center;
        color: #333;
    }
    .countdown_timer .digit {
        font-size: 1.2rem;
        text-align: center;
        color: #000;
    }
</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Countdown',
  props: ['source', 'options'],
  data () {
    return {
      intervalId: null,
      ended: false,
      beginTime: new Date('2022-09-01T00:00:00'),
      endTime: new Date('2022-09-30T23:59:59'),
      minutes: '',
      hours: '',
      days: '',
      text: ''
    }
  },
  methods: {
    updateTimer () {
      var now = new Date().getTime()
      var distance = this.beginTime - now
      this.text = 'The jam will begin in'
      if (this.beginTime < now) {
        distance = this.endTime - now
        this.text = 'The jam will end in'
      }
      if (this.endTime < now) {
        this.ended = true
        this.text = 'The jam has ended'
      }
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    }
  },
  mounted () {
    this.intervalId = setInterval(this.updateTimer, 1000)
  },
  unmounted () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
})
</script>
