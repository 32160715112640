<template>
  <div :class="'button ' + ( dark_button ? 'dark_button' : '' )">
    <a :href="href" :download="isDownload" >Download.</a>
  </div>
</template>
<style>
  .button a {
    transition: all .2s;
    display: block;
    font-weight: 600;
    text-align: center;
    padding: .25rem 1.25rem;
    margin: 0 auto;
    max-height: 50px;
    border-radius: 4px;
    box-shadow: 0px 4px 3px rgba(0,0,0,.25);
    text-decoration: none;
    border: 1px solid #707070;
    text-shadow: 0 1px 0px #494949;
    background: v-bind('"linear-gradient(180deg,"+accent+"," + gradientShift(accent) + ")"');
    color: #FFF;
    text-shadow: 0 1px 0px #494949;
  }

  .button a:hover {
    background: v-bind('"linear-gradient(0deg,"+accent+"," + accent + ")"');
  }
  .dark_button a {
    color: #000;
    text-shadow: 0 1px 0px #B5B5B5;
  }
</style>
<script>
import { defineComponent } from 'vue'
import * as Color from 'color'

export default defineComponent({
  name: 'LinkButton',
  props: ['href', 'isDownload', 'color'],
  data () {
    return {
    }
  },
  methods: {
    gradientShift (color) {
      const c = Color(color)
      if (c.luminosity > 0.5) {
        return c.darken(0.85).rotate(16)
      }
      return c.whiten(0.85).rotate(16)
    }
  },
  computed: {
    accent () {
      if (this.color) {
        return this.color
      }
      return '#2A2092'
    },
    dark_button () {
      if (this.color) {
        const c = Color(this.color)
        if (c.luminosity > 0.5) {
          return true
        }
      }
      return false
    }
  }
})
</script>
