<template>
    <div class="lazy_image"
      v-bind:style="{padding: '0 0 ' + (.25 + stackup * .75) + 'rem 0' }"
    >
        <figure class="image__wrap"
          v-bind:style="{ height: sizeToPx(height), width: sizeToPx(width)}"
          :source="imageSource"
          v-blurload
        >
          <component :is="el(link)" class="image__link" :to="link" :href="link">
            <img class="image__img"
              @load="loadedImage"
              ref="my_image"
              key="imageSource"
            >
            <canvas class="image__blur"
              ref="canvas" width="32" height="32"
              v-bind:style="{ height: sizeToPx(height), width: sizeToPx(width)}"
              :key="'blur'"
            />
            <div v-for="i in stackup" class="image__stack"
              v-bind:style="{ height: sizeToPx(height), width: sizeToPx(width), right: ((stackup-i)*3)+'px', top: ((stackup-i)*6)+'px'}"
              :key="'blur'+i"
              />
          </component>
        </figure>
    </div>
</template>
<style>
    .image__link {
      padding: 0;
      margin: 0;
    }
    .image__img, .image__blur {
        opacity: 1.0;
        border: 0;
        display: block;
        box-shadow: 0px 3px 3px rgba(0,0,0,.5);
        box-sizing: border-box;
    }

    .image__stack {
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: 0px 3px 3px rgba(0,0,0,.5);
    }

    .image__blur {
      position: absolute;
      top: 0%;
      right: 0%;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .image__img{
      position: relative;
      z-index:1;
      height: auto;
      max-width: 100%;
    }
    .image__wrap {
      margin: 0;
      position: relative;
    }

    .image__wrap, .image__blur, .image__stack {
      white-space: nowrap;
    }
    .lazy_image {
      transition: transform .2s ease-in-out;
    }
    .lazy_image .image__img,
    .lazy_image .image__stack,
    .lazy_image .image__blur {
      transition: border .2s, border-radius .2s ease-in-out, opacity .3s ease-in;
      border-radius: 5%;
    }

    .lazy_image:hover .image__stack,
    .lazy_image:hover .image__img,
    .lazy_image:hover .image__blur {
      border-radius: 1%;
    }
</style>
<script>
import { defineComponent } from 'vue'
import { decode } from 'blurhash'

export default defineComponent({
  name: 'LazyImage',
  props: ['source', 'blurhash', 'height', 'width', 'link', 'stacksize'],
  computed: {
    stackup () {
      if (this.stacksize > 0) {
        return Math.min(this.stacksize, 3)
      }
      return 0
    },
    imageSource () {
      return this.source
    }
  },
  watch: {
    blurhash (oldHash, newHash) {
      this.setupBlur(newHash)
    }
  },
  beforeUpdate () {
    this.setupBlur(this.blurhash)
    this.$refs.my_image.src = this.imageSource
  },
  methods: {
    el (url) {
      if (url) {
        return url.startsWith('http') ? 'a' : 'router-link'
      }
      return 'div'
    },
    isNumber (n) {
      return typeof n === 'number' && !isNaN(n) && isFinite(n)
    },
    sizeToPx (size) {
      if (size === 0) {
        return '100%'
      }
      if (this.isNumber(size)) {
        return size + 'px'
      }
      return size
    },
    setupBlur (newHash) {
      if (newHash != null) {
        const pixels = decode(newHash, 32, 32)
        const ctx = (this.$refs.canvas).getContext('2d')
        const imageData = ctx.createImageData(32, 32)
        imageData.data.set(pixels)
        ctx.putImageData(imageData, 0, 0)
      } else {
        const pixels = decode('LEHLk~WB2yk8pyo0adR*.7kCMdnj', 32, 32)
        const ctx = (this.$refs.canvas).getContext('2d')
        const imageData = ctx.createImageData(32, 32)
        imageData.data.set(pixels)
        ctx.putImageData(imageData, 0, 0)
      }
      this.$refs.canvas.style.opacity = 1.0
      this.$refs.my_image.src = this.imageSource
    },
    loadedImage (event) {
      // console.log('LOADED IMAGE!')
      const p = event.target.parentNode
      const canvElement = Array.from(p.children).find(el => el.nodeName === 'CANVAS')
      if (canvElement) {
        canvElement.style.opacity = '0.0'
      }
      event.target.style.opacity = '1.0'
    }
  },
  mounted () {
    this.setupBlur(this.blurhash)
  }
})
</script>
