import { DirectiveBinding, VNode } from 'vue'

export default {
  mounted (el: HTMLElement, binding: DirectiveBinding<any>, vnode: VNode) {
    function loadImage () {
      const linkElement = Array.from(el.children).find(
        el => el.nodeName === 'A'
      )
      if (linkElement) {
        const imageElement = Array.from(linkElement.children).find(
          el => el.nodeName === 'IMG'
        ) as HTMLImageElement
        if (imageElement) {
          imageElement.addEventListener('error', () => console.log('error'))
          if (vnode.props && vnode.props.source) {
            imageElement.src = vnode.props.source
            vnode.props.blurred = false
          }
        }
      }
    }

    function handleIntersect (entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage()
          observer.unobserve(el)
        }
      })
    }

    function createObserver () {
      const options = {
        root: null,
        threshold: 0
      }
      const observer = new IntersectionObserver(handleIntersect, options)
      observer.observe(el)
    }
    if (window.IntersectionObserver) {
      createObserver()
    } else {
      loadImage()
    }
  }
}
