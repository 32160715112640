<template>
  <footer>
    <p><router-link to="/about">Games We Play In Dreams</router-link> is a project by <a href="https://twitter.com/Jeckdev">@Jeckdev</a>. You should follow me on twitter!</p>
  </footer>
</template>
<style>
footer a {
  border-bottom: 2px solid;
}
</style>
<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'AppFooter',
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
})
</script>
