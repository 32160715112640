
import axios from 'axios'
import { defineComponent } from 'vue'
import ArtifactOnShelfLazy from '@/components/ArtifactOnShelfLazy.vue'
import DreamGame from '@/components/DreamGame.vue'
import RandomizerButton from '@/components/RandomizerButton.vue'

export default defineComponent({
  name: 'Home',
  components: {
    ArtifactOnShelfLazy,
    RandomizerButton,
    DreamGame
  },
  data () {
    return {
      shelves: [],
      games: []
    }
  },
  methods: {
    shelfName (item) {
      if (item.name) {
        return item.name
      }
      return 'Unnamed Shelf (#' + item.id.toString() + ')'
    }
  },
  async created () {
    try {
      const response = await axios.get('/api/v1/root/')
      console.log('Hit response!')
      this.shelves = response.data.root
      this.games = response.data.games
    } catch (e) {
      console.log('Failed to connect to internal API!')
    }
  }
})
