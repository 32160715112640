import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import LazyLoadDirective from './directives/LazyLoadDirective'
import BlurLoadDirective from './directives/BlurLoadDirective'
import './assets/base.css'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueVirtualScroller from 'vue-virtual-scroller'

import VueResizeObserver from 'vue-resize-observer'

const app = createApp(App)
app.directive('lazyload', LazyLoadDirective)
app.directive('blurload', BlurLoadDirective)

app.use(store).use(router)
app.use(VueVirtualScroller).use(VueResizeObserver)

app.mount('#app')
