<template>
    <div class="palette">
        <div class="pal_color" v-for="(item, key, index) in colors" :key="'pal_'+index">
            <div class="box" v-bind:style="{ 'background-color': this.hexCode(item)}">&nbsp;</div>
            <div class="label">{{this.hexCode(item)}}</div>
        </div>
    </div>
</template>
<style>
    .palette { }
    .palette .pal_color {
        display: inline-block;
        margin: 1rem .5rem;
    }
    .palette .box {
        box-shadow: 4px 4px 3px rgba(0,0,0,.1);
        border: 1px solid #000;
        border-radius: 1px;
        text-align: center;
        width: 4rem;
        height: 4rem;
        margin: .2rem auto;
    }
    .palette .label {
        text-transform: uppercase !important;
        font-family: monospace;
        font-weight: bold;
        font-size: 1.3rem;
        display: block;
        vertical-align: top;
        text-align: center;
    }
</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaletteDisplay',
  props: ['colors'],
  methods: {
    hexCode (rgb) {
      if (rgb && rgb.length >= 3) {
        var r = Number(rgb[0]).toString(16)
        var g = Number(rgb[1]).toString(16)
        var b = Number(rgb[2]).toString(16)
        if (r.length === 1) r = '0' + r
        if (g.length === 1) g = '0' + g
        if (b.length === 1) b = '0' + b
        return '#' + r + g + b
      }
      return ''
    },
    invertCode (rgb) {
      if (rgb && rgb.length >= 3) {
        var dist0 = Math.abs(0 - rgb[0]) + Math.abs(0 - rgb[1]) + Math.abs(0 - rgb[2])
        var dist255 = Math.abs(255 - rgb[0]) + Math.abs(255 - rgb[1]) + Math.abs(255 - rgb[2])

        var lum = Number((dist0 > dist255) ? 0 : 255).toString(16)
        if (lum.length === 1) {
          lum = '0' + lum
        }
        return '#' + lum + lum + lum
      }
      return ''
    }
  }
})
</script>
