import { DirectiveBinding, VNode } from 'vue'

export default {
  mounted (el: HTMLElement, binding: DirectiveBinding<any>, vnode: VNode) {
    function loadImage () {
      const linkElement = Array.from(el.children).find(
        el => el.nodeName === 'A'
      )
      if (linkElement) {
        const imageElement = Array.from(linkElement.children).find(
          el => el.nodeName === 'IMG'
        ) as HTMLImageElement
        if (imageElement) {
          imageElement.addEventListener('error', () => console.log('error'))
          if (vnode.props && vnode.props.source) {
            imageElement.src = vnode.props.source
          }
        }
      }
    }

    loadImage()
  }
}
