
import { defineComponent } from 'vue'
import ArtifactDisplay from '@/components/ArtifactDisplay.vue' // @ is an alias to /src

export default defineComponent({
  name: 'ArtifactInfo',
  props: ['id'],
  components: {
    ArtifactDisplay
  }
})
