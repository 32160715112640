<template>
  <div class="shelf">
    <div :class="metadata.ribbon != 'none' ? 'shelf_ribbon':'shelf_noribbon'">
      <img v-if="metadata.cover" :src="'/artifact/'+metadata.cover" />
      <h1 v-else>{{metadata.name}}</h1>
    </div>
    <ShelfTest :shelf="id" :metadata="metadata" :artifacts="artifacts" :first="this.$route.query.art" expand="false"/>
  </div>
</template>
<style scoped>
  .shelf_meta {
    background-color: v-bind('metadata.inner_bg');
    padding: 2rem;
    margin: 0 auto;
    max-width: 960px;
  }
  .shelf_ribbon {
    background-color: v-bind('metadata.ribbon_bg');
    box-shadow: inset 0px -4px 8px rgba(0,0,0,.1);
  }
  .shelf_ribbon h1, .shelf_noribbon h1 {
    padding: 2rem;
    margin: 0;
  }
  .shelf_noribbon img, .shelf_ribbon img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
    padding: 2rem;
  }
  .shelf_meta .markdown,
  .shelf_meta .shelf_related {
    padding: 1rem 2rem;
  }
  .shelf {
    text-align: left;
    font-family: v-bind('metadata.font_family');
    color: v-bind('metadata.text_color');
    background-color: v-bind('metadata.bg_color');
    padding: 0;
    margin: 0;
  }
  .shelf_meta .markdown a,.shelf_related a:visited {
    color: v-bind('metadata.link_color');
  }
</style>
<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import ShelfTest from '@/components/ShelfTest.vue' // @ is an alias to /src

export default defineComponent({
  name: 'Shelf',
  props: ['id', 'first'],
  components: {
    ShelfTest
  },
  data () {
    return {
      artifacts: [],
      metadata: {},
      parents: [],
      children: []
    }
  },
  async beforeRouteUpdate (to, from) {
    // react to route changes...
    if (to.params.id === from.params.id) {
      return
    }
    await this.fetchShelf(to.params.id)
  },
  methods: {
    async fetchShelf (shelfID) {
      try {
        const response = await axios.get('/api/v1/shelf/' + shelfID + '/')
        console.log('Hit response!')
        console.log(response.data)
        this.artifacts = response.data.shelf.artifacts
        this.metadata = response.data.shelf.metadata
        this.parents = response.data.shelf.parents
        this.children = response.data.shelf.children
      } catch (e) {
        console.log('Failed error!')
      }
    }
  },
  created () {
    this.fetchShelf(this.id)
  }
})
</script>
