<template>
<div class="rand_button">
    <router-link to="/randomizer">
        <div class="open">
            <img src="../assets/ob_1.png">
        </div>
        <div class="closed">
            <img src="../assets/cb_1.png">
        </div>
    </router-link>
</div>
</template>
<style>
  .rand_button {
    position: relative;
    flex: 0 0 256px;
    min-height: 188px;
    cursor: grab !important;
    top: -150px;
  }
  .rand_button .open, .rand_button .closed {
    transition: opacity .2s;
    position: absolute;
    background: white;
    opacity: 1.0;
  }
  .rand_button .open {
    z-index: -1;
  }
  .rand_button:hover .closed {
    opacity: 0;
  }
  .rand_button img {
    width: v-bind('isTall ? "100%" : "auto"');
    height: v-bind('isTall ? "auto" : "100%"');
    object-fit: contain;
  }
    @media screen and (max-width: 940px) {
        .rand_button {
        /*single column layout*/
        display: inline;
        float: left;
        position: static;
        }
        .rand_button .open,
        .rand_button .closed {
          position: static;
        }
        .rand_button .closed {
          display: none;
        }
    }
    @media screen and (max-width: 640px) {
        .rand_button {
          display: none;
        }
    }
</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RandomizerButton',
  props: ['isTall'],
  components: {
  }
})
</script>
