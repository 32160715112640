<template>
  <div>
    <a class="a_game" :href="game.link">
        <LazyImage
        class="game_icon"
        :source="imgSource(game.artifact.id)"
        :blurhash="game.artifact.metadata.blurhash"
        :stacksize="0"
        />
        <div class="game_info">
            <div class="game_title">{{game.title}}</div>
            <div class="game_author">by <span>{{game.author}}</span></div>
            <p class="game_description">{{game.description}}</p>
        </div>
    </a>
  </div>
</template>
<style>
.a_game {
    text-decoration: none;
    display: flex;
    margin: 1rem;
    padding: 1rem;
    flex-direction: row;
    border: 2px solid #FFF !important;
    border-bottom: 2px dotted #CCC !important;
}
.a_game:hover .game_icon {
    transform: scale(1.03);
}
.a_game:hover {
    box-shadow: 0px 4px 6px #DADADA;
    background: #F0F0F0;
    border-bottom: 2px solid #CCC !important;
    border: 2px solid #CCC !important;
}
.a_game .game_icon {
    flex: 0 0 auto;
    max-width: 128px;
    min-width: 94px;
    margin-right: 1rem;
}
.game_info {
    color: #001942;
    font-weight: 400;
}
.game_title {
    font-size: 1.4rem;
    font-weight: 600;
}
.game_author {
    font-size: 1.2rem;
    color: #777;
}
.game_author span {
    color: #001942;
}
.game_description {
    font-size: 1rem;
}
    @media screen and (max-width: 640px) {
        .a_game {
          display: block;
        }
    }
</style>
<script>
import { defineComponent } from 'vue'
import LazyImage from '@/components/LazyImage.vue' // @ is an alias to /src
export default defineComponent({
  name: 'DreamGame',
  components: {
    LazyImage
  },
  props: {
    game: { type: Object }
  },
  methods: {
    imgSource (artifactId) {
      return '/srv/artifact/' + artifactId + '/g/thumb_188/'
    }
  }
})
</script>
